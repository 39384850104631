module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"What to Expect When You're Electing","short_name":"WTEWYE","start_url":"/","background_color":"#0d3bb0","theme_color":"#4083f2","display":"standalone","icon":"src/assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f61dc520390492e6e3fae0d298a2275f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://126c06790e41478a80d849bdc6889504@o437901.ingest.sentry.io/5448150"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
