// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mission-jsx": () => import("./../../../src/pages/mission.jsx" /* webpackChunkName: "component---src-pages-mission-jsx" */),
  "component---src-pages-pledge-jsx": () => import("./../../../src/pages/pledge.jsx" /* webpackChunkName: "component---src-pages-pledge-jsx" */),
  "component---src-pages-pledge-wall-jsx": () => import("./../../../src/pages/pledge-wall.jsx" /* webpackChunkName: "component---src-pages-pledge-wall-jsx" */),
  "component---src-pages-quiz-jsx": () => import("./../../../src/pages/quiz.jsx" /* webpackChunkName: "component---src-pages-quiz-jsx" */),
  "component---src-pages-settings-jsx": () => import("./../../../src/pages/settings.jsx" /* webpackChunkName: "component---src-pages-settings-jsx" */),
  "component---src-pages-social-pledge-jsx": () => import("./../../../src/pages/social-pledge.jsx" /* webpackChunkName: "component---src-pages-social-pledge-jsx" */),
  "component---src-pages-social-question-jsx": () => import("./../../../src/pages/social-question.jsx" /* webpackChunkName: "component---src-pages-social-question-jsx" */)
}

